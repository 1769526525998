/**
 * Layout for landing page
 * with Gatsby
 *
 * Author : vijay verma
 * Created : 04-Jan-2020
 */

import React from "react"
import { Link } from "gatsby"

import "../fonts/fonts.css"
import "./layout.scss"
import Footer from "./footer"

class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    let header = <>
                  <div className="dash-nav-w">
                    <div className="brand">
                      <a href="#">
                        <img src="/logo-black.png"/>
                      </a>
                    </div>
                    <div className="dash-menu">
                      <ul className="nav-list">
                        <li><a href="#">Design</a></li>
                        <li><a href="#">Develop</a></li>
                        <li><a href="#">Resource</a></li>
                        <li><a href="#">Blog</a></li>
                      </ul>
                    </div>
                  </div>
                 </>
    return (
      <div className="dashboard-wrapper">
        <nav>{header}
        </nav>
        <main className="dash-main">{children}</main>
        <Footer />
      </div>
    )
  }
}

export default Layout
