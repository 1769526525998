/**
 * Index Page
 *
 * Author : vijay verma
 * Created : 28-Jan-2020
 */

import React from "react"
import { Link, graphql } from "gatsby"

import Sushi from "../components/sushi"
import Layout from "../components/layout-dashboard"
import SEO from "../components/seo"

class GetStarted extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="All posts" />
        <section className="dash-wrap">
          <div className="side-nav">
            <ul className="top-menu">
              <li>
                <a href="#">Geting started</a>
                <ul className="second-menu">
                  <li className="active"><a href="#">Introduction</a></li>
                  <li ><a href="/principle">Principle</a></li>
                </ul>
              </li>
              <li>
                <a href="#">Foundations</a>
                <ul className="second-menu">
                  <li><a href="#">Color</a></li>
                  <li><a href="#">Typography</a></li>
                  <li><a href="#">Introduction</a></li>
                </ul>
              </li>
              <li>
                <a href="#">Voice & Tone</a>
              </li>
              <li>
                <a href="#">Brands</a>
              </li>
              <li>
                <a href="#">Blog</a>
              </li>
            </ul>

          </div>


          <div className="content-area">
            <div className="contents">
            <h1 className="content-head">Sushi Design System</h1>
            <p>Sushi is Zomato’s very own design system, which helps build robust user interfaces following a clean and simple design language.</p>
            <p>While we work towards creating our own design language through Sushi, we at the same time, fully embrace and adhere to the native UI guidelines of Android, iOS and other platforms.</p>
            <p>Sushi as a design system and a reference for brand guidelines, is utilised and represented by various teams within Zomato, such as - Product, Engineering, Marketing and Branding.</p>
            </div>
          </div>

        </section>
      </Layout>
    )
  }
}

export default GetStarted

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
